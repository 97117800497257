import { CrmOnceSubject, crmResolveExpression } from 'common-module/core';
import { CrmResolvable } from 'common-module/core/types';
import { EMPTY, isObservable, of, switchMap } from 'rxjs';

import { StaticHolder } from '../../static/static-holder';

import { ConfirmModalComponent } from './confirm-modal.component';
import { ConfirmModalData } from './confirm-modal.data';

/**
 * Function decorator which wraps method with confirmation modal window
 *
 * @param data
 * @param onDiscard
 * @param discardResult
 * @constructor
 */
export const Confirm = <Target, DR>(
  data: CrmResolvable<ConfirmModalData, Target>,
  onDiscard?: (target: Target) => void,
  discardResult?: DR,
) => {
  return (_target: Target, _name: string, descriptor: PropertyDescriptor) => {
    const value = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      const _this = this as Target;

      return crmResolveExpression({ resolvable: data, source: _this }).pipe(
        switchMap((resolvedData) => {
          const subject = new CrmOnceSubject<ConfirmModalComponent['result']>();

          StaticHolder.modal.createHandlers<
            'success' | 'discard',
            ConfirmModalData,
            ConfirmModalComponent
          >(
            {
              nzContent: ConfirmModalComponent,
              nzData: resolvedData,
              nzMaskClosable: false,
              nzClosable: false,
              nzKeyboard: false,
              nzOnCancel: () => subject.next('discard'),
            },
            {
              onSuccess: (success) => subject.next(success),
              onDiscard: () => subject.next('discard'),
            },
          );

          return subject.asObservable();
        }),
        switchMap((success) => {
          if (success === 'success') {
            const result = value.apply(_this, args);

            if (isObservable(result)) {
              return result;
            }

            return of(result);
          }

          onDiscard?.(_this);
          return discardResult != null ? of(discardResult) : EMPTY;
        }),
      );
    };
  };
};
