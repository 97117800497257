import { APP_INITIALIZER, Provider } from '@angular/core';
import { CrmModalService } from 'common-module/modal';

/**
 * Class which holds global instances of root services, main usage in decorators
 */
export class StaticHolder {
  static modal: CrmModalService;
}

/**
 * Provider for static holder, initiates holder properties with required services
 */
export const staticHolderProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [CrmModalService],
  useFactory: (modal: CrmModalService) => () => {
    StaticHolder.modal = modal;
  },
};
